.modal {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  width: 700px;
  height: auto;
  max-width: 80%;
  max-height: 90%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  overflow: auto;
  min-width: 350px;
}

.modal-shade {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 350px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

@media (max-width: 500px) {
  .form {
    width: 100%;
    height: 100%;
  }
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  font-weight: bold;
}

input[type='text'],
input[type='email'],
input[type='tel'] {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-left: 10px;
  flex-grow: 1;
}

textarea {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-left: 10px;
  flex-grow: 1;
  resize: none;
  height: 6rem;
}

.modal button {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: lightblue;
  color: white;
  cursor: pointer;
}

.form-image {
  width: 50%;
  height: auto;
  margin-bottom: 2rem;
  border-radius: 3px;
}

.exit-btn {
  position: absolute;
  top: -10px;
  right: -15px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 2px gray;
}

.form-paw-icon {
  margin-top: 0.25rem;
}

.exit-btn:hover {
  background: var(--clr-gold);
  color: var(--clr-white);
}

/* CSS - SUBMIT FORM BUTTON */
.button-81 {
  border: 0 solid #e2e8f0;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #0d172a;
  cursor: pointer;
  display: inline-block;
  font-family: 'Basier circle', -apple-system, system-ui, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  text-decoration: none #0d172a solid;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 1px 2px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-81:hover {
  background: var(--clr-gold);
  color: var(--clr-white);
}

@media (min-width: 768px) {
  .button-81 {
    font-size: 1.125rem;
    padding: 1rem 2rem;
  }
}

/* Highlighter Effect of the Form Labels and Title */
mark {
  margin: 0 -0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em 0.3em;
  background: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 225, 0, 0.1),
    rgba(255, 225, 0, 0.7) 4%,
    rgba(255, 225, 0, 0.3)
  );
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

/* Circular Framing of Dog Image in Form */

.dog {
  align-items: center;
}
.dog img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: var(--light-shadow);
}

.phone-number {
  font-weight: bold;
  color: black;
  text-decoration: none; /* Removes underline by default on links */
}

.phone-number span {
  color: inherit; /* Inherit parent link's color */
}
